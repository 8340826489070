import { Link } from "gatsby"
import React from "react"

const Footer = () => (
    <footer className="footer">
      <div className="footer--copyright">
        <div className="footer--copyright__text">
          <span>© {new Date().getFullYear()}
          {` `}
          Writera Limited. All Rights Reserved.</span>
          <Link to='/terms-of-use/'>Terms of use</Link>
          <Link to='/privacy-policy/'>Privacy policy</Link>
        </div>
      </div>
    </footer>
)

export default Footer